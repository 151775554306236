// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-pbf-js": () => import("./../../../src/templates/aboutPBF.js" /* webpackChunkName: "component---src-templates-about-pbf-js" */),
  "component---src-templates-article-detail-js": () => import("./../../../src/templates/articleDetail.js" /* webpackChunkName: "component---src-templates-article-detail-js" */),
  "component---src-templates-article-overview-js": () => import("./../../../src/templates/articleOverview.js" /* webpackChunkName: "component---src-templates-article-overview-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/contentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-event-v-2-js": () => import("./../../../src/templates/eventV2.js" /* webpackChunkName: "component---src-templates-event-v-2-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-participation-js": () => import("./../../../src/templates/participation.js" /* webpackChunkName: "component---src-templates-participation-js" */),
  "component---src-templates-past-forum-detail-js": () => import("./../../../src/templates/pastForumDetail.js" /* webpackChunkName: "component---src-templates-past-forum-detail-js" */),
  "component---src-templates-past-forums-js": () => import("./../../../src/templates/pastForums.js" /* webpackChunkName: "component---src-templates-past-forums-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-session-detail-js": () => import("./../../../src/templates/sessionDetail.js" /* webpackChunkName: "component---src-templates-session-detail-js" */),
  "component---src-templates-speaker-detail-js": () => import("./../../../src/templates/speakerDetail.js" /* webpackChunkName: "component---src-templates-speaker-detail-js" */)
}

