import React, { useEffect, useReducer } from "react";
import cookiesContext from "./cookiesContext";
import cookiesReducer from "./cookiesReducer";

const CookiesState = ({ children }) => {
  let browserCookies = [];
  if (typeof document !== `undefined`) {
    browserCookies = document?.cookie?.split(";");
  }
  const checkForCookie = (arr, cookie) =>
    arr.some(item => item?.trim()?.startsWith(cookie));
  const cookieConsent = checkForCookie(browserCookies, "cookieConsent");
  const statisticalCookies = checkForCookie(
    browserCookies,
    "statisticalCookies"
  );
  const marketingCookies = checkForCookie(browserCookies, "marketingCookies");

  const initialState = {
    cookies: [true, statisticalCookies, marketingCookies],
    cookiesSetted: cookieConsent,
  };

  const [state, dispatch] = useReducer(cookiesReducer, initialState);

  const updateCookies = cookies => {
    dispatch({
      type: "UPDATE_COOKIES",
      payload: cookies,
    });
  };

  const cookiesSettedTo = show => {
    dispatch({
      type: "SHOW_COOKIE_BANNER",
      payload: show,
    });
  };

  return (
    <cookiesContext.Provider
      value={{
        cookies: state.cookies,
        cookiesSetted: state.cookiesSetted,
        updateCookies,
        cookiesSettedTo,
      }}
    >
      {children}
    </cookiesContext.Provider>
  );
};
export default CookiesState;
