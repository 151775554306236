module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Frient","short_name":"frient","start_url":"/","background_color":"#4b6d73","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dd36d119fe4be62e23e06e80c82be95b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://frient-pbf.matomo.cloud/","siteUrl":"https://www.frient-peacebuilding-forum.de","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","localScript":"//cdn.matomo.cloud/frient-pbf.matomo.cloud/matomo.js","requireConsent":true,"requireCookieConsent":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
